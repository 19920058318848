<template>
  <base-view
      @update:search-values="updateSearch"
      :search-columns="searchColumns">
      <template v-slot:actions:extras>
        <v-btn color="primary"
               @click="esvaziarLixeira"
               :block="$vuetify.breakpoint.mobile" :outlined="$vuetify.breakpoint.mobile" :width="'200px'"
               :text="!$vuetify.breakpoint.mobile"> Esvaziar Lixeira
        </v-btn>
      </template>
      <faturas-lixeira-data-table ref="dataTable"
                            @click:recover="recuperar"
                            @click:remove="remover"
                         :search-values="searchValues"/>
  </base-view>

</template>

<script>
import FaturasLixeiraDataTable from "../../../components/shared/datatables/FaturasLixeiraDataTable";
import BaseView from "../../BaseView";
import {limpaLixeira, recuperaDaLixeira, removeDaLixeira} from "../../../api/faturas";
import {findAll as findAllCliente} from '../../../api/clientes';
import {findAll as findAllEventos} from '../../../api/eventos';
import {findAll as findAllLocal} from '../../../api/locais';
import {ItemDeDominio} from "../../../api/itemsDominio";
import {mapActions} from "vuex";
import {ApiError} from "../../../api/core";
import * as moment from 'moment';

export default {
  name: "index",
  components: {BaseView, FaturasLixeiraDataTable},
  data() {
    return {
      item: null,
      editDialogOpened: false,
      selectedItems: [],
      searchValues: {},
      searchColumns: [
        {
          name: 'Cliente',
          column: 'idCliente',
          type: 'autocomplete',
          optionsFactory: (search) => findAllCliente(1, {
            nome: search
          })
              .then(r => {
                return r.elements.map(e => ({
                  key: e.id,
                  valor: e.nome
                }))
              })

        },
        {
          group: 'Período',
          columns: [
            {
              name: 'De',
              column: 'dtInicio',
              type: 'date',
              defaultValue: moment().startOf('month').format('YYYY-MM-DD'),
            },
            {
              name: 'Até',
              column: 'dtFim',
              type: 'date',
              defaultValue: moment().endOf('month').format('YYYY-MM-DD'),
            },
          ]
        },
        {
          name: 'Descrição',
          column: 'descricao',
          type: 'text'
        },
        {
          name: 'Tipo Plano',
          column: 'tipoPlano',
          type: 'select',
          optionsFactory: () => this.fetch({
            itemDominio: ItemDeDominio.TIPO_PLANO
          })
        },
        {
          name: 'Local',
          column: 'idLocal',
          type: 'autocomplete',
          optionsFactory: (search) => findAllLocal(1,{
            descricao: search
          })
              .then(r => {
                return r.elements.map(e => ({
                  key: e.id,
                  valor: e.descricao
                }))
              })

        },
        {
          name: 'Competição',
          column: 'idEvento',
          type: 'autocomplete',
          optionsFactory: (search) => findAllEventos(1, {
            nome: search
          })
              .then(r => {
                return r.elements.map(e => ({
                  key: e.id,
                  valor: e.descricao
                }))
              })
        },
        {
          name: 'Tipo Fatura',
          column: 'tipoFatura',
          type: 'select',
          optionsFactory: () => this.fetch({
            itemDominio: ItemDeDominio.ARR_TIPO_FATURA
          })
        },
        {
          name: 'Status',
          column: 'status',
          type: 'select',
          optionsFactory: () => this.fetch({
            itemDominio: ItemDeDominio.STS_PAGAMENTO
          })
        },
      ]
    }
  },
  methods: {
    ...mapActions('itemsDominio', ['fetch']),
    updateSearch(search) {
      this.searchValues = search;
    },
    async esvaziarLixeira() {
      try {
        await limpaLixeira();
        this.$toast2(`Lixeira esvaziada com sucesso.`, {
          timeout: 3000
        });
        this.$refs.dataTable.refresh();
      } catch (err) {
        if(err instanceof ApiError) {
          this.$toast.error(err.message, {
            timeout: 6000
          })
        } else {
          this.$toast.error(`Não foi possível esvaziar a Lixeira.`, {
            timeout: 6000
          })
        }
      }
    },
    async recuperar({id}) {
      try {
        await recuperaDaLixeira(id);
        this.$toast.success(`Fatura recuperada com sucesso.`, {
          timeout: 3000
        });
        await this.$refs.dataTable.refresh();
      } catch (err) {
        if(err instanceof ApiError) {
          this.$toast.error(err.message, {
            timeout: 6000
          })
        } else {
          this.$toast.error(`Não foi possível recuperar a Fatura.`, {
            timeout: 6000
          })
        }
      }
    },
    async remover({id}) {
      try {
        await removeDaLixeira(id);
        this.$toast.success(`Fatura removida definitivamente com sucesso.`, {
          timeout: 3000
        });
        this.$refs.dataTable.refresh();
      } catch (err) {
        if(err instanceof ApiError) {
          this.$toast.error(err.message, {
            timeout: 6000
          })
        } else {
          this.$toast.error(`Não foi possível remover a Fatura definitivamente.`, {
            timeout: 6000
          })
        }
      }
    }
  },
  mounted() {
  }
}
</script>

<style scoped lang="scss">

::v-deep {
  .v-data-table__wrapper {
    height: calc(100vh - 157px);
  }
}

</style>